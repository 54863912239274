:root {
  --background-gray: #EEEEEE;
  --card-color: #ffffff;
}

html {
  background-color: var(--background-gray);
}

.body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 
             'Segoe UI', Roboto, 'Helvetica Neue', 
             Ubuntu, Arial, sans-serif;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: var(--background-gray);
}

h1 { 
  font-family: "Brush Script MT", cursive;
  font-size: 2.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  color: #001064;
}

hr {
  width: 90%;
  overflow: hidden;
  text-align: center;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0.5vh 0.5vw;
  justify-content: center;
}

.flex-grid::after {
	display: inline-block;
}

.card {
  flex: 1 1 calc(90%/3);
  background-color: var(--card-color);
  border-radius: 8px;
  margin: 1vh 0.5vw;
  padding: 1%;
  max-width: calc(90%/3);
}

.card div {
  display: flex;
  flex-direction: column;
}

.card span {
  width: 90%;
  padding: 1.25% 2.5%; 
}

.card h3 {
  text-align: center;
}

.card hr {
  width: 90%;
  overflow: hidden;
  text-align: center;
}

@media (max-width: 1025px) {
  .flex-grid {
    flex-direction: column;
  }
  .card {
    align-self: center;
    margin: 1vh 1vw;
    max-width: 95%;
  }
}
